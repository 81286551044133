import { Route, Routes } from 'react-router-dom'
import routes from './config'
import { useNapCobranzas } from 'context'
import Page401 from 'pages/Errors/Page401'

const ConfigRoutes = () => {
  const { userInfo } = useNapCobranzas()

  return (
    <Routes>
      {routes.map((e, ind) => {
        const { path, element: Component, children, isPrivate } = e
        return isPrivate
          ? (
          <Route path={path} element={<Component />} exact={true} key={ind}>
            {children.map((el, indx) => {
              const { path: ruta, element: Element, roles } = el
              let flag = false
              roles.forEach((el) => {
                if (userInfo?.roles?.includes(el)) flag = true
              })
              return (
                <Route
                  path={ruta}
                  element={flag ? <Element /> : <Page401 />}
                  exact={true}
                  key={indx}
                />
              )
            })}
          </Route>
            )
          : children
            ? (
          <Route path={path} element={<Component />} exact={true} key={ind}>
            {children.map((el, indx) => {
              const { path: ruta, element: Element } = el
              return <Route path={ruta} element={<Element />} exact={true} key={indx} />
            })}
          </Route>
              )
            : (
          <Route path={path} element={<Component />} exact={true} key={ind} />
              )
      })}
    </Routes>
  )
}

export default ConfigRoutes
