import axios from 'axios'
import { API_NAPCOBRANZAS } from 'config/api.config'
import UserService from 'config/UserService'

export const postContracts = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/contract/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const getContracts = async () => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/contract/${UserService.company()}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const getContract = async (contractId) => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/contract/status/${contractId}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const getContractsToSelect = async () => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/contract/select/${UserService.company()}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const getClientContracts = async (clientId) => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/contract/${clientId}/${UserService.company()}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const putContracts = async (data, id) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/contract/${id}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const generateWork = async (id, data) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/contract/change-status/${id}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const exportContracts = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/contract/export/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const updateNumTvs = async (id, data) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/contract/numTvs/${id}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const validateContracts = async () => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/contract/verify/${UserService.company()}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}
