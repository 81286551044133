import StorageService from './StorageService'
import { SESSION_USER } from 'config/session'

class UserService {
  static all = StorageService.get(SESSION_USER)

  // static data() {
  //   return this.all?.data ?? "";
  // }

  static token () {
    return this.all ? this.all.token : null
  }

  static user () {
    return this.all ? this.all.user : null
  }

  static company () {
    return this.all ? this.all.company : null
  }

  // static f_name() {
  //   return this.all?.data.f_name ?? "";
  // }

  // static l_name() {
  //   return this.all?.data.l_name ?? "";
  // }

  // static fullname() {
  //   return `${this.all?.data.l_name ?? ""} ${this.all?.data.f_name ?? ""}`;
  // }

  // static roles() {
  //   return this.all?.data.roles ?? "";
  // }

  // static n_doc() {
  //   return this.all?.data.n_doc ?? "";
  // }

  // static photo() {
  //   return this.all?.data.profile_picture ?? "";
  // }
}

export default UserService
