import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useNapCobranzas } from 'context'
import React, { useEffect, useState } from 'react'

const PrinterConfig = ({ sendData }) => {
  const { company } = useNapCobranzas()

  const [typePrinter, setTypePrinter] = useState('')

  useEffect(() => {
    if (company.typePrinter) setTypePrinter(company.typePrinter)
  }, [company])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 'normal' }}>
            Tipo de Impresion
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            - Termica:
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            - Bluetooth:
          </Typography>
        </Box>
        <FormControl sx={{ minWidth: 250 }}>
          <InputLabel>Tipo de Impresión</InputLabel>
          <Select
            value={typePrinter}
            label="Tipo de Impresión"
            onChange={(e) => {
              setTypePrinter(e.target.value)
              sendData({ typePrinter: e.target.value })
            }}
          >
            <MenuItem value="TERMICA">TERMICA</MenuItem>
            <MenuItem value="BLUETOOTH">BLUETOOTH</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  )
}

export default PrinterConfig
