import axios from 'axios'
import UserService from 'config/UserService'
import { API_PAGOS } from 'config/api.config'

export const getCompanyStatus = async (roles) => {
  const res = await axios.post(
    `${API_PAGOS}/information/${UserService.company()}`,
    { roles },
    { headers: { Authorization: UserService.token() } }
  )
  return res.data
}
