import axios from 'axios'
import { API_NAPCOBRANZAS } from 'config/api.config'
import UserService from 'config/UserService'

export const postProducts = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/product/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const importProducts = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/product/import/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const getProducts = async () => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/product/${UserService.company()}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const putProducts = async (data, id) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/product/${id}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}
