import BackDrop from 'components/BackDrop'
import { createContext, useContext, useState, useEffect } from 'react'
import { getCompanyStatus, getUserInfo } from 'requests'
import sleep from 'utils/sleep'
import { useNavigate, useLocation } from 'react-router-dom'
import AuthenticationService from 'config/AuthenticationService'
import UserService from 'config/UserService'

export const NapCobranzas = createContext()
export const useNapCobranzas = () => useContext(NapCobranzas)

const exclude = ['/home', '/login', '/forgot-password']

const NapCobranzasProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()
  // DATA CONTEXT
  const [userInfo, setUserInfo] = useState({})
  const [company, setCompany] = useState({})
  const [isAuth, setIsAuth] = useState(false)

  const [infoPayment, setInfoPayment] = useState({})

  useEffect(() => {
    if (!exclude.includes(location.pathname) || UserService.token()) getData()
    else setLoading(false)
  }, [])

  const getData = async () => {
    try {
      setLoading(true)
      const res = await getUserInfo()

      if (res.message) {
        setIsAuth(true)
        setUserInfo(res.data)
        const data = await getCompanyStatus(res.data.roles, company._id)
        setInfoPayment(data)
        if (location.pathname === '/login') navigate('/')
      } else {
        await redirectTO()
      }
    } catch (error) {
      await AuthenticationService.logout()
      await redirectTO()
    } finally {
      setLoading(false)
    }
  }

  const redirectTO = async () => {
    await sleep(200)
    navigate('/login')
  }

  return (
    <NapCobranzas.Provider value={{ userInfo, company, setCompany, isAuth, infoPayment }}>
      {children}
      <BackDrop loading={loading} />
    </NapCobranzas.Provider>
  )
}
export default NapCobranzasProvider
