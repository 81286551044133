import React from 'react'

const Spinner = () => {
  return (
    <div className="spinner_container">
      <div className="spinner_content">
        <div className="loading"></div>
        <div className="loading2"></div>
        <p>Cargando...</p>
      </div>
    </div>
  )
}

export default Spinner
