import StorageService from './StorageService'
import { SESSION_USER } from 'config/session'
import { authUser } from 'requests'

class AuthenticationService {
  static async login(obj) {
    const data = await authUser(obj)
    if (data.paymentData) return data

    const dataToSave = {
      token: data.token,
      user: data.data._id,
      company: data.data.companies[0]._id
    }

    StorageService.set(SESSION_USER, dataToSave)

    return data.data
  }

  static logout() {
    return new Promise((resolve) => {
      StorageService.remove(SESSION_USER)
      resolve()
    })
  }
}

export default AuthenticationService
