import { Grid, Typography, FormHelperText } from '@mui/material'

const Content = ({ title, helper, component, children }) => {
  return (
    <Grid container spacing={1} justifyContent="space-between" alignItems="center">
      <Grid item>
        {helper && <FormHelperText component="div">{helper}</FormHelperText>}
        {title && (
          <Typography variant="h4" component="div">
            {title}
          </Typography>
        )}
      </Grid>
      {component && <Grid item>{component}</Grid>}
      {children && (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}
    </Grid>
  )
}

export default Content
