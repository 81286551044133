import axios from 'axios'
import { API_NAPCOBRANZAS } from 'config/api.config'
import UserService from 'config/UserService'

export const postWorks = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/work/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const importWorks = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/work/import/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const getWorks = async () => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/work/${UserService.company()}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const getFreeWorks = async () => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/work/tecnico/free/${UserService.company()}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const getWorksByUser = async (userId) => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/work/tecnico/${userId}/${UserService.company()}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const getAllWorks = async () => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/work/all/${UserService.company()}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const getWorksFiltered = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/work/range-status/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const getWorksByDay = async (date) => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/work/${UserService.company()}/${date}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const putWorks = async (data, id) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/work/${id}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const addProductsToWork = async (data, workId) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/work/add-products/${workId}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const completeWork = async (workId, data = {}) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/work/complete/${workId}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const acceptWork = async (workId) => {
  const res = await axios.put(
    `${API_NAPCOBRANZAS}/work/accept/${workId}/${UserService.user()}`,
    {},
    {
      headers: { Authorization: UserService.token() }
    }
  )
  return res.data
}

export const deleteWork = async (workId, data) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/work/delete/${workId}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}
