import axios from 'axios'
import { API_NAPCOBRANZAS } from 'config/api.config'
import UserService from 'config/UserService'

export const postActivities = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/activity/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const importActivities = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/activity/import/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const getActivities = async () => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/activity/${UserService.company()}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const putActivities = async (data, id) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/activity/${id}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}
