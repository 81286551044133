import { Paper, Switch, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useNapCobranzas } from 'context'
import { useEffect, useState } from 'react'

const BoxConfig = ({ sendData }) => {
  const { company } = useNapCobranzas()

  const [boxWithAmountZero, setBoxWithAmountZero] = useState(false)

  useEffect(() => {
    if (company._id) {
      setBoxWithAmountZero(company?.boxWithAmountZero)
    }
  }, [company._id])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 'normal' }}>
            Monto 0
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Si esta opción está activa la caja se abrirá con un monto de cero.
          </Typography>
        </Box>
        <Paper elevation={6} sx={{ background: '#F7F7F7' }}>
          <Switch
            checked={boxWithAmountZero}
            defaultChecked={boxWithAmountZero}
            onChange={() => {
              setBoxWithAmountZero(!boxWithAmountZero)
              sendData({ boxWithAmountZero: !boxWithAmountZero })
            }}
            color="success"
            size="medium"
          />
        </Paper>
      </Box>
    </Box>
  )
}

export default BoxConfig
