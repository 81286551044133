import axios from 'axios'
import { API_NAPCOBRANZAS } from 'config/api.config'
import UserService from 'config/UserService'

export const getVouchers = async (body) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/voucher/${UserService.company()}`, body, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const getVouchersById = async (id) => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/voucher/${id}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const putVouchers = async (body, serieId) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/voucher/pay/${serieId}`, body, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const deleteVouchers = async (voucherId) => {
  const res = await axios.delete(`${API_NAPCOBRANZAS}/voucher/${voucherId}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const getVouchersPaidByRange = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/voucher/paid/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}
