import axios from 'axios'
import { API_NAPCOBRANZAS } from 'config/api.config'
import UserService from 'config/UserService'

export const authUser = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/auth/signin`, data)
  return res.data
}

export const getUserInfo = async () => {
  if (UserService.user() && UserService.company()) {
    const res = await axios.get(
      `${API_NAPCOBRANZAS}/auth/validToken/${UserService.user()}/${UserService.company()}`,
      { headers: { Authorization: UserService.token() } }
    )
    return res.data
  } else {
    return null
  }
}

export const postUsers = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/auth/signup/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const getUsers = async () => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/user/${UserService.company()}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const getUsersByRoles = async (role) => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/user/${UserService.company()}/${role}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const putUsers = async (data, id) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/user/${id}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const changePassword = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/auth/change_password`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const changeProfilePicture = async (data, id) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/user/profile-picture/${id}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}
