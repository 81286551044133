/* eslint-disable eqeqeq */
import { styled } from '@mui/material/styles'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import BoxConfig from './components/BoxConfig'
import { useNapCobranzas } from 'context'
import Page from 'components/Page'
import Content from 'components/Content'
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material'
import { putCompaniesConfig } from 'requests/companies'
import { notification } from 'antd'
import FacturationConfig from './components/FacturationConfig'
import PagosConfig from './components/PagosConfig'
import PrinterConfig from './components/PrinterConfig'
import MessageConfig from './components/MessageConfig'

const CustomAccordion = styled((props) => <Accordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,

    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  })
)

const CustomAccordionSummary = styled((props) => (
  <AccordionSummary expandIcon={<ArrowForwardIosIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: '2px',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}))

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}))

function OtherConfig() {
  const { company, setCompany, userInfo } = useNapCobranzas()

  const [actualConfig, setActualConfig] = useState(false)
  const [companyChanges, setCompanyChanges] = useState({})

  const handleChange = (newValue) => setActualConfig(newValue)

  const handleCompanyChange = (newValue) => {
    setCompanyChanges({
      ...companyChanges,
      ...newValue
    })
  }

  const AccordionList = [
    {
      value: 'cuadreDeCaja',
      label: 'Caja',
      roles: ['superadmin', 'admin'],
      component: <BoxConfig sendData={handleCompanyChange} />
    },
    {
      value: 'facturation',
      label: 'Facturación',
      roles: ['superadmin'],
      component: <FacturationConfig sendData={handleCompanyChange} />
    },
    {
      value: 'pagos',
      label: 'Pagos ',
      roles: ['superadmin', 'admin'],
      component: <PagosConfig sendData={handleCompanyChange} />
    },
    {
      value: 'impresion',
      label: 'Impresión',
      roles: ['superadmin'],
      component: <PrinterConfig sendData={handleCompanyChange} />
    },
    {
      value: 'mensajes',
      label: 'Mensajes WhatsApp',
      roles: ['superadmin', 'admin'],
      component: <MessageConfig sendData={handleCompanyChange} />
    }
  ]

  const handleSave = () => {
    if (Object.keys(companyChanges).length > 0) {
      putCompaniesConfig(companyChanges)
        .then(() => {
          notification.success({
            message: 'Configuración guardada',
            description: 'Se ha guardado la configuración de la empresa'
          })
          setCompany({ ...company, ...companyChanges })
        })
        .catch(() => {
          notification.error({
            message: 'Error al guardar la configuración',
            description: 'No se ha podido guardar la configuración de la empresa'
          })
        })
    } else {
      notification.warning({
        message: 'No hay cambios',
        description: 'No se ha guardado ningún cambio'
      })
    }
  }

  return (
    <>
      <Page title="Configuración">
        <Content
          title="Configuración"
          helper="EMPRESAS"
          component={
            <Button variant="contained" onClick={handleSave}>
              Guardar Cambios
            </Button>
          }
        >
          {AccordionList.map(({ value, label, component, roles }) => {
            let flag = false
            roles.forEach((el) => {
              if (userInfo?.roles?.includes(el)) flag = true
            })

            if (flag) {
              return (
                <CustomAccordion key={value} onChange={() => handleChange(actualConfig == value ? false : value)}>
                  <CustomAccordionSummary>
                    <Typography variant="subtitle1">{label}</Typography>
                  </CustomAccordionSummary>
                  <CustomAccordionDetails>{component}</CustomAccordionDetails>
                </CustomAccordion>
              )
            }

            return null
          })}
        </Content>
      </Page>
    </>
  )
}

export default OtherConfig
