import axios from 'axios'
import { API_NAPCOBRANZAS } from 'config/api.config'
import UserService from 'config/UserService'

export const getPayments = async (clientId, year) => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/payment/${clientId}/${UserService.company()}/${year}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const putPayment = async (paymentId, data) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/payment/${paymentId}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const payPayments = async (body, serieId) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/payment/pay/${serieId}/${UserService.company()}`, body, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const deletePayments = async (paymentId) => {
  const res = await axios.delete(`${API_NAPCOBRANZAS}/payment/${paymentId}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const assignDiscounts = async (body) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/payment/discounts`, body, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const getLastPayment = async (clientId) => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/payment/last-payment/${clientId}/${UserService.company()}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const generateManualPayment = async (body, serieId) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/payment/manual/${serieId}`, body, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const generatePayments = async (data, contractId) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/payment/generate/${contractId}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

// export const getPaymentsPaid = async () => {
//   const res = await axios.get(`${API_NAPCOBRANZAS}/payment/paid/${UserService.company()}`, {
//     headers: { Authorization: UserService.token() },
//   });
//   return res.data;
// };

// export const getPaymentsPaidByRange = async (data) => {
//   const res = await axios.put(`${API_NAPCOBRANZAS}/payment/paid/${UserService.company()}`, data, {
//     headers: { Authorization: UserService.token() },
//   });
//   return res.data;
// };
