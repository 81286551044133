/* eslint-disable react/no-deprecated */
import 'simplebar/src/simplebar.css'
import 'assets/styles/custom-antd.css'
import 'assets/styles/spinner.css'
import 'assets/styles/custom.css'
import 'assets/styles/animation.css'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { StrictMode } from 'react'
import App from './App'
// ----------------------------------------------------------------------

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
)
