import axios from 'axios'
import { API_NAPCOBRANZAS } from 'config/api.config'
import { SESSION_USER } from 'config/session'
import StorageService from 'config/StorageService'

const SESSION_DATA = StorageService.get(SESSION_USER)

export const USER = SESSION_DATA?.user
export const TOKEN = SESSION_DATA?.token
export const COMPANY = SESSION_DATA?.company

export const CobranzasAxios = axios.create({ baseURL: API_NAPCOBRANZAS, headers: { Authorization: TOKEN } })
