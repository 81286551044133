import { createContext, useContext, useLayoutEffect, useState } from 'react'
import { notification } from 'antd'
import { getCompaniesNapCobranzas } from 'requests/client'
import sleep from 'utils/sleep'
// import UserService from 'config/UserService'
import { useNapCobranzas } from 'context'

export const ClientContext = createContext()
export const useClientContext = () => useContext(ClientContext)

// const exclude = ['/login']

const ClientContextProvider = ({ children }) => {
  const { isAuth } = useNapCobranzas()
  const [load, setLoading] = useState(true)
  const [clients, setClients] = useState([])

  useLayoutEffect(() => {
    if (isAuth) obtainClients()
  }, [isAuth])

  const obtainClients = async () => {
    try {
      setLoading(true)
      const res3 = await getCompaniesNapCobranzas()
      setClients(res3.reverse())
    } catch (error) {
      notification.error({
        message: 'Ocurrió un error al realizar la operación.',
        description: 'Volviendo a intentar en 60 segundos...'
      })
      await sleep(60000)
      await obtainClients()
    } finally {
      setLoading(false)
    }
  }

  return <ClientContext.Provider value={{ load, clients, obtainClients }}>{children}</ClientContext.Provider>
}

export default ClientContextProvider

// const formatData = (data, system) => {
//   const format = []

//   data
//     .filter(item => item.active) // item.ruc.toString().length === 11 &&
//     .map(item => ({
//       logo: item.logo ?? '',
//       ruc: item.ruc ?? '',
//       name: item.name ?? '',
//       sedes: item.sedes ?? data.filter(sd => sd.ruc === item.ruc).map(sd => sd.tradeName ?? sd.name),
//       system,
//       phone: item.phone ?? '',
//       email: item.email ?? '',
//       address: item.address ?? '',
//       createdAt: item.createdAt ?? '',
//       _id: item._id ?? ''
//     }))
//     .forEach(item => !format.find(i => i.ruc === item.ruc) && format.push(item))

//   return format
// }
