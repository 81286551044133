import OtherConfig from 'pages/others/OtherConfig'
import { lazy } from 'react'
import Loadable from './Loadable'
// ----------------------------------------------------------------------
const Page404 = Loadable(lazy(() => import('pages/Errors/Page404')))
// ----------------------------------------------------------------------
const DashboardLayout = Loadable(lazy(() => import('layouts/dashboard')))
const FreeLayout = Loadable(lazy(() => import('layouts/FreeLayout')))
// ----------------------------------------------------------------------
const Login = Loadable(lazy(() => import('pages/Login')))
const Home = Loadable(lazy(() => import('pages/Home')))
// ----------------------------------------------------------------------
const ForgotPassword = Loadable(lazy(() => import('pages/Errors/ForgotPwd')))
// ----------------------------------------------------------------------
const Dashboard = Loadable(lazy(() => import('pages/Dashboard')))
const Companies = Loadable(lazy(() => import('pages/Companies')))
const Users = Loadable(lazy(() => import('pages/Users')))
const Contracts = Loadable(lazy(() => import('pages/Contracts')))
const Changelog = Loadable(lazy(() => import('pages/Contracts/Changelog')))
const Services = Loadable(lazy(() => import('pages/Services')))
const Nodos = Loadable(lazy(() => import('pages/Nodos')))
const Inventory = Loadable(lazy(() => import('pages/Inventory')))
const Works = Loadable(lazy(() => import('pages/Works')))
const Activities = Loadable(lazy(() => import('pages/Activities')))
const Movements = Loadable(lazy(() => import('pages/Movements')))
const Payments = Loadable(lazy(() => import('pages/Payments')))
const Pending = Loadable(lazy(() => import('pages/Sunat/Pending')))
const Comprobants = Loadable(lazy(() => import('pages/Sunat/Comprobants')))
const Manuel = Loadable(lazy(() => import('pages/Sunat/Manual')))
const Generate = Loadable(lazy(() => import('pages/Sunat/Generate')))
const Historical = Loadable(lazy(() => import('pages/Sunat/Historical')))
const DetailsComprobant = Loadable(lazy(() => import('pages/Sunat/components/Details')))
const DetailsPDF = Loadable(lazy(() => import('pages/Sunat/components/PDF')))
const Discounts = Loadable(lazy(() => import('pages/Discounts')))
const Clients = Loadable(lazy(() => import('pages/Clients')))
const Reports = Loadable(lazy(() => import('pages/Reports')))
const StatusReport = Loadable(lazy(() => import('pages/Reports/Status')))
const ResumeReport = Loadable(lazy(() => import('pages/Reports/Resume')))
const VouchersReport = Loadable(lazy(() => import('pages/Reports/Vouchers')))
const AccountingReport = Loadable(lazy(() => import('pages/Reports/Accounting')))
const MethodsReport = Loadable(lazy(() => import('pages/Reports/Metodos')))
const GeneralReport = Loadable(lazy(() => import('pages/Reports/General')))
const CanceledReport = Loadable(lazy(() => import('pages/Reports/Canceled')))
const WorkReport = Loadable(lazy(() => import('pages/Reports/Work')))
const MethodPayments = Loadable(lazy(() => import('pages/Methods')))

const CuadreCaja = Loadable(lazy(() => import('pages/CuadreCaja')))
const CajaRegistradora = Loadable(lazy(() => import('pages/CajaRegistradora')))

const PrintersConfig = Loadable(lazy(() => import('pages/Printers')))

const ListaDeudas = Loadable(lazy(() => import('pages/ListaDeudas')))
const ListaPagos = Loadable(lazy(() => import('pages/Payments/ListaPagos')))

const Import = Loadable(lazy(() => import('pages/Import')))

const Zones = Loadable(lazy(() => import('pages/Zones')))
const Taps = Loadable(lazy(() => import('pages/Taps')))

const TecnicoFreeWork = Loadable(lazy(() => import('pages/Tecnico/FreeWorks')))
const TecnicoMyWorks = Loadable(lazy(() => import('pages/Tecnico/MyWorks')))
const TecnicoAllWorks = Loadable(lazy(() => import('pages/Tecnico/AllWorks')))
// ----------------------------------------------------------------------
// OTROS
const Profile = Loadable(lazy(() => import('pages/Profile')))

const routes = [
  {
    path: '/home',
    element: FreeLayout,
    roles: [],
    isPrivate: false,
    children: [
      {
        path: '',
        element: Home,
        roles: []
      }
    ]
  },
  {
    path: '/',
    roles: ['superadmin', 'admin'],
    element: DashboardLayout,
    isPrivate: true,
    children: [
      {
        path: '',
        element: Dashboard,
        roles: ['superadmin', 'admin']
      },
      {
        path: 'perfil',
        element: Profile,
        roles: ['superadmin', 'admin', 'caja', 'tecnico']
      },
      // COMPONENTS
      {
        path: 'companies',
        element: Companies,
        roles: ['superadmin']
      },
      {
        path: 'usuarios',
        element: Users,
        roles: ['superadmin']
      },
      {
        path: 'clients',
        element: Clients,
        roles: ['superadmin', 'admin', 'caja']
      },
      {
        path: 'cajas-registradoras',
        element: CajaRegistradora,
        roles: ['superadmin', 'admin']
      },
      {
        path: 'cuadre-caja',
        element: CuadreCaja,
        roles: ['superadmin', 'admin', 'caja']
      },
      {
        path: 'contratos',
        element: Contracts,
        roles: ['superadmin', 'admin', 'caja']
      },
      {
        path: 'contratos/historial-cambios',
        element: Changelog,
        roles: ['superadmin', 'admin', 'caja']
      },
      {
        path: 'servicios',
        element: Services,
        roles: ['superadmin', 'admin']
      },
      {
        path: 'pagos',
        element: Payments,
        roles: ['superadmin', 'admin', 'caja']
      },
      {
        path: 'pagos/lista-pagos',
        element: ListaPagos,
        roles: ['superadmin', 'admin', 'caja']
      },
      {
        path: 'lista-deudas',
        element: ListaDeudas,
        roles: ['superadmin', 'admin', 'caja']
      },
      {
        path: 'sunat/pendientes',
        element: Pending,
        roles: ['superadmin', 'admin']
      },
      {
        path: 'sunat/emitidos',
        element: Comprobants,
        roles: ['superadmin', 'admin']
      },
      {
        path: 'sunat/generar',
        element: Manuel,
        roles: ['superadmin', 'admin']
      },
      {
        path: 'sunat/historico',
        element: Historical,
        roles: ['superadmin', 'admin']
      },
      {
        path: 'sunat/registrar',
        element: Generate,
        roles: ['superadmin']
      },
      {
        path: 'sunat/detail-voucher/:idVoucher/:type/:from',
        element: DetailsComprobant,
        roles: ['superadmin', 'admin']
      },
      {
        path: 'sunat/detail-pdf/:idVoucher/:type',
        element: DetailsPDF,
        roles: ['superadmin', 'admin']
      },
      {
        path: 'descuentos',
        element: Discounts,
        roles: ['superadmin', 'admin']
      },
      {
        path: 'reportes',
        element: Reports,
        roles: ['superadmin', 'admin', 'contador']
      },
      {
        path: 'nodos',
        element: Nodos,
        roles: ['superadmin', 'admin', 'tecnico']
      },
      {
        path: 'trabajos',
        element: Works,
        roles: ['superadmin', 'admin']
      },
      {
        path: 'actividades',
        element: Activities,
        roles: ['superadmin', 'admin']
      },
      {
        path: 'stock/productos',
        element: Inventory,
        roles: ['superadmin', 'admin']
      },
      {
        path: 'stock/movimientos',
        element: Movements,
        roles: ['superadmin', 'admin']
      },
      {
        path: 'reportes/historial',
        element: ResumeReport,
        roles: ['superadmin', 'admin', 'contador']
      },
      {
        path: 'reportes/estado',
        element: StatusReport,
        roles: ['superadmin', 'admin', 'contador']
      },
      {
        path: 'reportes/contabilidad',
        element: AccountingReport,
        roles: ['superadmin', 'admin', 'contador']
      },
      {
        path: 'reportes/metodos',
        element: MethodsReport,
        roles: ['superadmin', 'admin', 'contador']
      },
      {
        path: 'reportes/general',
        element: GeneralReport,
        roles: ['superadmin', 'admin', 'contador']
      },
      {
        path: 'reportes/anulados',
        element: CanceledReport,
        roles: ['superadmin', 'admin', 'contador']
      },
      {
        path: 'reportes/trabajos',
        element: WorkReport,
        roles: ['superadmin', 'admin', 'contador']
      },
      {
        path: 'reportes/:type',
        element: VouchersReport,
        roles: ['superadmin', 'admin', 'contador']
      },
      {
        path: 'metodos/pago',
        element: MethodPayments,
        roles: ['superadmin', 'admin']
      },
      {
        path: 'config/impresoras',
        element: PrintersConfig,
        roles: ['superadmin', 'admin']
      },
      {
        path: 'config/others',
        element: OtherConfig,
        roles: ['superadmin', 'admin']
      },
      {
        path: 'importaciones',
        element: Import,
        roles: ['superadmin', 'admin']
      },
      {
        path: 'zonas',
        element: Zones,
        roles: ['superadmin', 'admin', 'tecnico']
      },
      {
        path: 'taps',
        element: Taps,
        roles: ['superadmin', 'admin', 'tecnico']
      },
      {
        path: 'tecnico/trabajos-libres',
        element: TecnicoFreeWork,
        roles: ['superadmin', 'admin', 'tecnico']
      },
      {
        path: 'tecnico/mis-trabajos',
        element: TecnicoMyWorks,
        roles: ['superadmin', 'admin', 'tecnico']
      },
      {
        path: 'tecnico/todos-los-trabajos',
        element: TecnicoAllWorks,
        roles: ['superadmin', 'admin']
      },
      // IF VIEW DOESN'T EXIST
      {
        path: '*',
        element: Page404,
        roles: ['superadmin', 'admin']
      }
    ]
  },
  {
    path: '/login',
    element: Login,
    isPrivate: false,
    roles: []
  },
  {
    path: '/forgot-password',
    element: ForgotPassword,
    isPrivate: false,
    roles: []
  },
  {
    path: '*',
    element: Page404,
    isPrivate: false,
    roles: []
  }
]

export default routes
