/* eslint-disable react/display-name */
import { Helmet } from 'react-helmet-async'
import { forwardRef } from 'react'
// material
import { Box } from '@mui/material'
import Scrollbar from './Scrollbar'
import { useNapCobranzas } from 'context'

// ----------------------------------------------------------------------
const Page = forwardRef(({ children, title = '', ...other }, ref) => {
  const { infoPayment } = useNapCobranzas()

  const HEIGHT = `calc((100vh) - ${infoPayment?.isActive ? 90 : 35}px)`

  return (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{title} | NapCobranzas</title>
      </Helmet>
      <Scrollbar sx={{ height: HEIGHT }}>
        <main style={{ height: HEIGHT, position: 'relative' }}>{children}</main>
      </Scrollbar>
    </Box>
  )
}
)

export default Page
