import axios from 'axios'
import { API_NAPCOBRANZAS } from 'config/api.config'
import UserService from 'config/UserService'

export const getDashboard = async (date) => {
  // date tiene que tener formato (DD/MM/YYYY)
  const res = await axios.get(
    `${API_NAPCOBRANZAS}/dashboard/${UserService.company()}/${date}`,
    { headers: { Authorization: UserService.token() } }
  )
  return res.data
}
