import { Paper, Switch, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useNapCobranzas } from 'context'
import { useEffect, useState } from 'react'

const FacturationConfig = ({ sendData }) => {
  const { company } = useNapCobranzas()

  const [isAmazonia, setIsAmazonia] = useState(false)

  useEffect(() => {
    if (company._id) setIsAmazonia(company?.isAmazonia)
  }, [company._id])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 'normal' }}>
            Amazonia
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Si está opción está activa tus comprobantes no serán afectados al IGV.
          </Typography>
        </Box>
        <Paper elevation={6} sx={{ background: '#F7F7F7' }}>
          <Switch
            checked={isAmazonia}
            defaultChecked={isAmazonia}
            onChange={() => {
              setIsAmazonia(!isAmazonia)
              sendData({ isAmazonia: !isAmazonia })
            }}
            color="success"
            size="medium"
          />
        </Paper>
      </Box>
    </Box>
  )
}

export default FacturationConfig
