import axios from 'axios'
import { API_NAPCOBRANZAS } from 'config/api.config'
import UserService from 'config/UserService'

export const postNodos = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/nodo/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const importNodos = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/nodo/import/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const getNodos = async () => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/nodo/${UserService.company()}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const getContractsByNodos = async (nodoId) => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/nodo/contracts/${nodoId}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const putNodos = async (data, id) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/nodo/${id}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}
