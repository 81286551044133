import axios from 'axios'
import { API_NAPCOBRANZAS } from 'config/api.config'
import UserService from 'config/UserService'

export const postServices = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/service/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const importServices = async (data) => {
  const res = await axios.post(`${API_NAPCOBRANZAS}/service/import/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const getServices = async () => {
  const res = await axios.get(`${API_NAPCOBRANZAS}/service/${UserService.company()}`, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}

export const putServices = async (data, id) => {
  const res = await axios.put(`${API_NAPCOBRANZAS}/service/${id}`, data, {
    headers: { Authorization: UserService.token() }
  })
  return res.data
}
