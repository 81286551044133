import * as React from 'react'
import Backdrop from '@mui/material/Backdrop'
import Spinner from 'components/Spinner'

const BackDrop = ({ loading }) => {
  return (
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: '#f2f2f2' }}
      open={loading}
    >
      <Spinner />
    </Backdrop>
  )
}
export default BackDrop
