const { useState, useEffect } = require('react')

const useDebounce = (val, delay = 500) => {
  const [value, setValue] = useState(val)

  useEffect(() => {
    const handler = setTimeout(() => {
      setValue(val)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [val])

  return value
}

export default useDebounce
