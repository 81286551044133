import { Paper, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { notification } from 'antd'
import { useNapCobranzas } from 'context'
import { useState, useEffect } from 'react'

const PagosConfig = ({ sendData }) => {
  const { company } = useNapCobranzas()
  const [monthsGenerated, setMonthsGenerated] = useState('0')

  const validate = (months) => {
    if (parseInt(months) < 0) {
      notification.error({ message: 'El número de meses debe ser mayor a 0' })
      setMonthsGenerated('0')
    }
    setMonthsGenerated(months)
    sendData({ monthsGenerated: parseInt(months) })
  }

  useEffect(() => {
    if (company._id) {
      setMonthsGenerated(company?.monthsGenerated)
    }
  }, [company._id])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 'normal' }}>
            Meses generados
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Indica el número de meses generados al finalizar un pago.
          </Typography>
        </Box>
        <Paper elevation={6} sx={{ background: '#F7F7F7' }}>
          <TextField
            label="Meses generados"
            type="number"
            value={monthsGenerated}
            onChange={(e) => {
              validate(e.target.value)
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Paper>
      </Box>
    </Box>
  )
}

export default PagosConfig
