import { Divider, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useNapCobranzas } from 'context'
import useDebounce from 'hooks/useDebounce'
import React, { useEffect, useState } from 'react'

const vars = [
  {
    label: 'Cliente',
    value: 'JUAN PEREZ'
  },
  {
    label: 'Monto',
    value: 100
  },
  {
    label: 'Descripcion',
    value: 'OCTUBRE 2022'
  },
  {
    label: 'Servicio',
    value: 'SERVICIO DE CABLE'
  },
  {
    label: 'Direccion',
    value: 'JR CALLO 465'
  },
  {
    label: 'Vencimiento',
    value: '12/10/2022'
  }
]
const variables = vars.map((ev) => ev.label.toUpperCase())

const MessageConfig = ({ sendData }) => {
  const { company } = useNapCobranzas()
  const [messageOne, setMessageOne] = useState('')
  const [messageTwo, setMessageTwo] = useState('')
  const [tradOne, setTradOne] = useState('')
  const [tradTwo, setTradTwo] = useState('')

  const value = useDebounce(messageOne, 500)
  const valueTwo = useDebounce(messageTwo, 500)

  useEffect(() => {
    if (company) {
      setMessageOne(company?.messageOne)
      setMessageTwo(company?.messageTwo)
    }
  }, [company._id])

  const translateMessage = (val) => {
    let temp = val
    vars.forEach((ev) => {
      temp = temp?.replace(`$${ev.label.toUpperCase()}`, ev.value)
    })
    return temp
  }

  useEffect(() => {
    setTradOne(translateMessage(value))
    sendData({ messageOne, messageTwo })
  }, [value])

  useEffect(() => {
    setTradTwo(translateMessage(valueTwo))
    sendData({ messageOne, messageTwo })
  }, [valueTwo])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ width: '45%' }}>
          <Typography variant="h6" sx={{ fontWeight: 'normal', marginBottom: '10px' }}>
            Instrucciones
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            - Las variables a utilizar son las siguientes: <b>{variables.join(', ')}</b>
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            - Para utilizar una variable debe de anteponer el símbolo de dólar ($) seguido del nombre de la variable.
            Ejemplo: <b>$CLIENTE</b>
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            - Las variables deben de ser escritas en mayúsculas, de lo contrario no se reconocerán.
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            - Las variables solo pueden ser utilizadas una vez.
          </Typography>
        </Box>
        <Box sx={{ width: '45%' }}>
          <Typography variant="h6" sx={{ fontWeight: 'normal', marginBottom: '10px' }}>
            Datos de prueba
          </Typography>
          {vars.map((ev) => (
            <Typography key={ev.value} variant="body2" sx={{ color: 'text.secondary' }}>
              <b>{ev.label}</b>: {ev.value}
            </Typography>
          ))}
        </Box>
      </Box>

      <Typography variant="h6" sx={{ fontWeight: 'normal', paddingBottom: '10px' }}>
        Deudas de 1 mes
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ width: '45%' }}>
          <TextField
            fullWidth
            label="INGRESE SU MENSAJE"
            value={messageOne}
            onChange={(e) => setMessageOne(e.target.value)}
            rows={4}
            multiline
          />
        </Box>
        <Divider orientation="vertical" />
        <Box sx={{ width: '45%' }}>
          <TextField label="RESULTADO SU MENSAJE" fullWidth disabled value={tradOne} rows={4} multiline />
        </Box>
      </Box>

      <Typography variant="h6" sx={{ fontWeight: 'normal', paddingBottom: '10px' }}>
        Deudas mayores a 1 mes
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ width: '45%' }}>
          <TextField
            fullWidth
            label="INGRESE SU MENSAJE"
            value={messageTwo}
            onChange={(e) => setMessageTwo(e.target.value)}
            rows={4}
            multiline
          />
        </Box>
        <Divider orientation="vertical" />
        <Box sx={{ width: '45%' }}>
          <TextField label="RESULTADO SU MENSAJE" fullWidth disabled value={tradTwo} rows={4} multiline />
        </Box>
      </Box>
    </Box>
  )
}

export default MessageConfig
