import axios from 'axios'
import { API_NAPCOBRANZAS } from 'config/api.config'
import UserService from 'config/UserService'

const ReportIns = axios.create({
  baseURL: `${API_NAPCOBRANZAS}/report`,
  headers: { Authorization: UserService.token() }
})

export const getReportAnualData = async (year, months) => {
  const res = await ReportIns.post(`/anual/${UserService.company()}/${year}`, { months })
  return res.data
}

export const getReportAnualStatus = async (year, months) => {
  const res = await ReportIns.post(`/status/${UserService.company()}/${year}`, { months })
  return res.data
}

export const getVoucherReportData = async (data) => {
  const res = await ReportIns.post(`/vouchers/${UserService.company()}`, data)
  return res.data
}

export const getAccountingReportData = async (data) => {
  const res = await ReportIns.post(`/accounting/${UserService.company()}`, data)
  return res.data
}

export const getMethodsReportData = async (data) => {
  const res = await ReportIns.post(`/methods/${UserService.company()}`, data)
  return res.data
}

export const getWorksReportData = async (data) => {
  const res = await ReportIns.post(`/works/${UserService.company()}`, data)
  return res.data
}

export const getGeneralReportData = async (data) => {
  const res = await ReportIns.post(`/general/${UserService.company()}`, data)
  return res.data
}

export const getCanceledReportData = async (data) => {
  const res = await ReportIns.post(`/anulados/${UserService.company()}`, data)
  return res.data
}
