import ConfigRoutes from 'routes'
import ThemeConfig from 'theme'
import GlobalStyles from 'theme/globalStyles'
import { BrowserRouter } from 'react-router-dom'
import NapCobranzasProvider from 'context'
import ClientContextProvider from 'context/client'
import moment from 'moment-timezone'
import 'moment/locale/es'

const App = () => {
  moment.tz.setDefault('America/Lima')

  return (
    <ThemeConfig>
      <GlobalStyles />
      <BrowserRouter>
        <NapCobranzasProvider>
          <ClientContextProvider>
            <ConfigRoutes />
          </ClientContextProvider>
        </NapCobranzasProvider>
      </BrowserRouter>
    </ThemeConfig>
  )
}

export default App
